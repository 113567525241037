<template>
  <card>
    <card-header :title="$t('Assignment info')" class="mb-4"></card-header>
    <template v-for="(info,index) in assignmentInfo" :key="'ls'+index">
      <div class="bg-primary-dim rounded p-2 mb-2" v-if="info.id">
        <b class="d-block mb-2">{{ info.assign_date }}</b>
        <user-small-card
            :user-name="info.first_name+' '+info.last_name"
            :user-email="info.email"
            :image="info.dp"
            :initials="info.initials">
          <template v-slot:more_info>
            <div class="mt-n1">
              <router-link v-if="userRole === 'is-ap-agent'" :to="{name: 'apAgentAdvisorProfile', params:{id: info.profile_id}}" target="_blank">{{ $t('view profile') }}</router-link>
              <router-link v-else :to="{name: info.type === 'advisor' ? 'advisorProfile' : 'adminApAgentProfile', params:{id: info.profile_id}}" target="_blank">{{ $t('view profile') }}</router-link>
            </div>
          </template>
        </user-small-card>
        <p class="mt-2 text-muted">{{ $t('pages.admin_lead_details.lead_assignment_msg1', {person_type: $t(info.type === 'advisor' ? 'advisor' : 'appointment agent'),  name: info.first_name+' '+info.last_name}) }}
          <br><span class="text-dark">{{ $t('Status')}}:</span> <span class="fw-500 text-info">{{ $t(info.status_text) }}</span> </p>
        <div v-if="userRole === 'is-admin' && info.returned && info.returned === 1" class="text-muted">
          <div class="text-danger">{{ $t('The')+' lead '+(info.return_info.return_status===3 ? 'is' : 'was')+' '+$t('returned') }}</div>
          <div><span class="fw-500">{{$t('Reason')}}:</span> {{ $t(info.return_info.reason)}}</div>
          <div><span class="fw-500">{{$t('Explanation')}}:</span> {{ info.return_info.explanation}}</div>
          <div v-if="info.return_info.return_status === 1" class="text-primary">{{$t('Return has been accepted')}}.</div>
          <div v-if="info.return_info.return_status === 2" class="text-primary">{{$t('Return has been rejected')}}.</div>
          <div class="mt-2" v-if="info.return_info.return_status === 3"> <!-- 3 = not yet responded -->
            <nk-button type="primary" v-on:click="[returnResponse.leadData=info.return_info, returnResponse.isModalOpen=true]">{{$t('Respond')}}</nk-button>
          </div>
        </div>
      </div>
    </template>
  </card>
  <respond-to-lead-return
      v-if="returnResponse.isModalOpen"
      :is-modal-open="returnResponse.isModalOpen"
      :lead-data="returnResponse.leadData"
      @close-modal="returnResponse.isModalOpen=false"
      @status-change="returnStatusChanged"></respond-to-lead-return>
</template>

<script>
import CardHeader from "@core/components/cards/CardHeader";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import Card from "@core/components/cards/Card";
import useStoreHelper from '@/store/helper'
import {reactive} from "vue";
import RespondToLeadReturn from "@/views/admin/Leads/components/RespondToLeadReturn";
export default {
  components: {RespondToLeadReturn, Card, UserSmallCard, CardHeader},
  props:{
    assignmentInfo: Array,
  },
  setup(){

    // Respond to lead return request
    let returnResponse = reactive({
      isModalOpen: false,
      leadData: null,
      new_status: '',
      comment: '',
    })
    const{userRole} = useStoreHelper()

    const returnStatusChanged = (d) => {
      returnResponse.leadData.return_status = parseInt(d.new_status)
      returnResponse.leadData.admin_comment = d.comment
      returnResponse.isModalOpen = false
    }

    return{
      returnResponse,
      returnStatusChanged,
      userRole,
    }
  }
}
</script>
