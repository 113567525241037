<template>
  <card no-padding stretch>
    <template v-slot:grouped>
      <card-inner>
        <card-header v-if="userRole === 'is-ap-agent' && leadData.status_text.toLowerCase() !== 'appointment'"
                     :title="$t('Advisors availability')"></card-header>
        <card-header v-else :title="$t(leadData.status_text && leadData.status_text.toLowerCase() === 'appointment' ? 'Assign appointment' : 'Assign lead')"></card-header>
      </card-inner>
      <card-inner v-if="userRole === 'is-admin'">
        <div class="form-inline flex-nowrap gx-3">
          <span class="nk-text-dark">{{ $t('List type') }}</span>
          <div class="form-wrap">
            <b-select v-model="usersListType">
              <option value="advisors">{{$t('Advisors')}}</option>
              <option value="ap_agents">{{$t('Appointment Agents')}}</option>
            </b-select>
          </div>
        </div>
      </card-inner>
      <div class="card-inner position-relative card-tools-toggle">
        <card-header title="">
          <card-tools>
            <div class="form-inline flex-nowrap gx-3">
              <span class="nk-text-dark">{{ $t('Search') }}</span>
              <div class="form-wrap">
                <input type="text" class="form-control" :placeholder="$t('search_by',{term: $t('name')})"
                       v-model="advisorsFilters.search" v-on:input="searchAdvisors"/>
              </div>
            </div>
          </card-tools>
          <card-tools>
            <drop-down icon="ni ni-setting" title="" size="sm" direction="right"
                       :dot="advisorsFilters.closest || advisorsFilters.less_leads ? 'primary' : ''"
                       class="d-block d-md-none">
              <ul class="link-check">
                <li><span>{{ $t('Order') }}</span></li>
                <li :class="{active: advisorsFilters.closest}">
                  <a href="javascript:;"
                     v-on:click="filterAdvisors('closest')">{{ $t('Closest') }}</a></li>
                <li :class="{active: advisorsFilters.less_leads}">
                  <a href="javascript:;" v-on:click="filterAdvisors('less_leads')">{{ $t('Lowest')+' # Leads' }}</a></li>
              </ul>
            </drop-down>
            <nk-button :type="advisorsFilters.closest ? 'primary' : 'light'"
                       :outline="!advisorsFilters.closest" size="md" class="mr-2 d-none d-md-block"
                       v-on:click="filterAdvisors('closest')">{{$t('Closest')}}</nk-button>
            <nk-button :type="advisorsFilters.less_leads ? 'primary' : 'light'"
                       :outline="!advisorsFilters.less_leads" size="md"
                       v-on:click="filterAdvisors('less_leads')" class="d-none d-md-block">{{$t('Lowest')}} # Leads</nk-button>
          </card-tools>
        </card-header>
      </div>
      <div class="card-inner p-0">
        <div class="p-3" v-if="advisorsSkeleton">
          <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
          <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
          <ion-skeleton-text animated class="w-50"></ion-skeleton-text>
          <ion-skeleton-text animated class="w-25"></ion-skeleton-text>
        </div>
        <list-table v-else>
          <tb-row head-row>
            <tb-th>{{ $t('Name')}}</tb-th>
            <tb-th break-point="mb">{{ $t('Distance') }}</tb-th>
            <tb-th break-point="md" v-if="userRole === 'is-admin'">Leads ({{ $t('this week')}})</tb-th>
            <tb-th break-point="md">{{$t('Appointments')}}</tb-th>
            <tb-th class="nk-tb-col-tools"></tb-th>
          </tb-row>

          <template v-for="user in usersList" :key="'usr'+user.id">
            <tb-row>
              <tb-td>
                <user-small-card
                    :user-name="user.first_name+' '+user.last_name"
                    :image="user.dp" :initials="user.initials" :user-email="user.email">
                  <template v-slot:more_info>
                    <div class="mt-n1 lh-n">
                      <span>{{ user.city}}</span>
                      <span class="small d-block" v-if="user.last_seen.date">
                                  <span class="nk-text-muted" v-if="user.last_seen.interval.length">{{ $t('Last seen') }}: {{ user.last_seen.interval+' '+$t('ago')}}</span>
                                  <template v-if="user.last_seen.interval.indexOf('seconds') === -1">
                                    <span class="fw-500 nk-text-muted">, {{ user.last_seen.date}}</span><span class="nk-text-muted"> {{$t('at')}} </span>
                                    <span class="fw-500 nk-text-muted">{{ user.last_seen.time}}</span>
                                  </template>
                                </span>
                      <span v-else class="small d-block">{{ $t('Last seen') }}: {{ $t('Never') }}</span>
                    </div>
                  </template>
                </user-small-card>
              </tb-td>
              <tb-td break-point="mb">
                <span class="fw-500 text-success">{{ user.google_matrix.distance.text.split(' ')[0]}}</span>
                <span class="fw-500 nk-text-muted text-uppercase mx-1">{{ user.google_matrix.distance.text.split(' ')[1]}}</span>
                <span class="nk-text-muted small"> (+/- {{ user.google_matrix.duration.text }})</span>
              </tb-td>
              <!-- if current user is admin -->
              <template v-if="userRole === 'is-admin'">
                <tb-td break-point="md" class="lh-n" v-if="usersListType === 'advisors'">
                  <div>
                    <span class="fw-500 text-success">{{ user.assigned_leads.this_week.paid}}</span>
                    <span class="fw-500 text-dark">/{{ user.available_leads}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('Paid') }}</span>
                  </div>
                  <div>
                    <span class="fw-500 text-dark">{{ user.assigned_leads.this_week.free}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('Free') }}</span>
                  </div>
                  <div>
                    <span class="fw-500 text-dark">{{ user.assigned_leads.this_week.total}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('Total') }}</span>
                  </div>
                  <a href="javascript:;" class="small text-muted"
                     v-on:click="viewAdvisorLastWeekLeadsCount(user, 'leads')"
                     style="text-decoration: underline">+{{$t('view')}} {{$t('last week')}}</a>
                </tb-td>
                <tb-td break-point="md" class="lh-n" v-else>
                  <div>
                    <span class="fw-500 text-success">{{ user.this_week_leads}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('This week') }}</span>
                  </div>
                  <div>
                    <span class="fw-500 text-dark">{{ user.converted_this_week}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('Converted this week') }}</span>
                  </div>
                  <div>
                    <span class="fw-500 text-dark">{{ user.leads_all_times}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('All times') }}</span>
                  </div>
                  <div>
                    <span class="fw-500 text-dark">{{ user.converted_all_times}}</span>
                    <span class="nk-text-muted">&ensp;{{ $t('Converted all times') }}</span>
                  </div>
                </tb-td>
              </template>
              <tb-td break-point="md" class="lh-n">
                <div>
                  <span class="fw-500 text-success">{{ user.appointments.this_week.total}}</span>
                  <span class="fw-500 text-dark">/{{ user.available_appointments}}</span>
                  <span class="nk-text-muted">&ensp;{{ $t('This week') }}</span>
                </div>
                <div>
                  <span class="fw-500 text-dark">{{ user.appointments.last_week.total}}</span>
                  <span class="nk-text-muted">&ensp;{{ $t('Last week') }}</span>
                </div>
              </tb-td>
              <tb-td>
                <nk-button type="success" size="sm" v-if="userRole === 'is-admin' || leadData.status_text.toLowerCase() === 'appointment'" v-on:click="assignLead(user)">
                  <nio-icon icon="ni-user-check"/> <span>{{ $t( leadData.status_text && leadData.status_text.toLowerCase() === 'appointment' ? 'Assign' : 'Assign lead' )}}</span>
                </nk-button>
              </tb-td>
            </tb-row>
            <tb-row class="bg-secondary-dim" v-if="usersListType === 'advisors' && user.availability.availability.length">
              <td colspan="5">
                <row class="no-gutters medium align-items-center">
                  <column default="auto" class="px-3 py-1 align-center">
                    <nio-icon icon="ni-curve-down-right" class="mr-1 h4 text-muted"></nio-icon>
                    <span>{{ $t('Availability') }}</span>
                  </column>
                  <column default="auto" class="px-3 py-1 lh-n text-center border-left" v-for="av in user.availability.availability" :key="'av'+user.id+'_'+av.date">
                    <div class="fw-500">{{av.date}} ({{ $t(av.day) }})</div>
                    <div class="text-muted">{{ av.time }}</div>
                  </column>
                </row>
              </td>
            </tb-row>
          </template>

        </list-table>
      </div>
      <div class="card-inner" v-if="pagination.totalRecords > 10">
        <pagination
            v-model="pagination.page"
            :records="pagination.totalRecords"
            :per-page="10"
            @paginate="paginationCallback"
            theme="bootstrap4"
            :options="{chunk: 5, texts:{count: '||'}}"/>
      </div>
    </template>
  </card>
</template>

<script>
import {Card, CardHeader, CardInner, CardTools} from "@core/components/cards";
import BSelect from "@core/components/bp-form/components/BSelect";
import {onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {DropDown} from "@core/components";
//import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import Pagination from "v-pagination-3"
import axios from "@/libs/axios";
import {useRoute} from "vue-router";
import {alertController, IonSkeletonText, isPlatform, loadingController, toastController} from "@ionic/vue";
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import ListTable from "@core/components/list-table/ListTable";
import TbRow from "@core/components/data-tables/components/TbRow";
import TbTh from "@core/components/data-tables/components/TbTh";
import TbTd from "@core/components/data-tables/components/TbTd";

export default {
  components: {
    TbTd,
    TbTh,
    TbRow,
    ListTable,
    Column,
    Row,
    UserSmallCard,
    /*TableCol, TableRow, SpecialTable, */DropDown, CardTools, CardHeader, BSelect, CardInner, Card, IonSkeletonText, Pagination,},
  props:{
    leadData: {
      type: Object,
      required: true,
    },
  },
  emits: ['onLeadAssigned'],
  setup(props, {emit}){

    const{endPointByRole,userRole} = useStoreHelper()
    const isDesktop = isPlatform('desktop')
    const route = useRoute()
    const store = useStore()
    const{t}  = useI18n()
    let usersListType = ref('advisors') // advisors || ap_agents

    // Advisors pagination logic
    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getUsersForLeadAssignment()
    }

    let usersList = ref([]) // Store users (for lead assignment) list in this array

    // Get advisors from server
    let advisorsSkeleton = ref(false)
    let cancelToken = false
    const getUsersForLeadAssignment = async () => {

      advisorsSkeleton.value = true

      if(false !== cancelToken){
        cancelToken()
        setTimeout(()=> advisorsSkeleton.value = true, 200)
        cancelToken = false
      }

      const data = {
        lead_id: route.params.id,
        filter: (advisorsFilters.closest) ? 'closest' : (advisorsFilters.less_leads ? 'assigned_less_leads' : ''),
        search: advisorsFilters.search,
        page: pagination.page,
      }
      const userRolePath = {
        'is-admin': 'admin',
        'is-ap-agent': 'ap_agent'
      }
      axios.post('/api/'+userRolePath[store.state.auth.user_role]+'/'+(usersListType.value === 'advisors' ? 'advisors' : 'ap_agent')+'/list/lead_assignment', data, {
        cancelToken: new axios.CancelToken( c => {
          cancelToken = c
        })
      })
          .then( (resp) => {
            if(!resp.data.success){
              console.log('Lead details error: '+resp.data.error);
            }
            else{
              usersList.value = resp.data.data.records
              if(pagination.page === 1){
                pagination.totalRecords = resp.data.data.total_records
              }
            }
          })
          .catch( err => {
            console.log('Advisors list ajax: '+err)
          })
          .then(() => {
            cancelToken = false
            advisorsSkeleton.value = false
          })
    }
    // Advisors list filters
    let advisorsFilters = reactive({
      search: '',
      closest: false, // Advisors closes (in distance) to this lead
      less_leads: false, // Advisors assigned less leads to
    })
    // Function to handle the other advisor filters
    const filterAdvisors = (filter) => {
      advisorsFilters.search = ''
      advisorsFilters.closest = advisorsFilters.less_leads = false
      advisorsFilters[filter] = true
      getUsersForLeadAssignment()
    }
    // Function to handle the search input
    const searchAdvisors = () => {
      advisorsFilters.closest = advisorsFilters.less_leads = false
      getUsersForLeadAssignment()
    }

    // Assign this lead to a user
    const assignLead = async ( user ) => {

      const loading = await loadingController.create({message: t('Processing...')})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let inputs = []
      if(userRole.value === 'is-admin' && usersListType.value === 'advisors'){
        inputs = [{
          name: 'price',
          type: 'text',
          cssClass: 'mt-0 form-control',
        }]
      }

      let iAlert = await alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        header: `${t(props.leadData.status_text?.toLowerCase() === 'appointment' ? 'Assign appointment' : 'Assign lead')}`,
        message: userRole.value === 'is-admin' && usersListType.value === 'advisors' ? t('Amount to add to advisor invoice')+': &euro;' : t('lead_assign_confirmation', {person_name: user.first_name+' '+user.last_name}),
        inputs: inputs,
        buttons: [
          {
            text: t('Cancel'),
            cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
          },
          {
            cssClass: isDesktop ? 'alert-btn-primary' : '',
            text: t('Confirm'), handler: (d) => {

              let price = 0;
              let postData = {lead_id: route.params.id}
              if(userRole.value === 'is-admin' && usersListType.value === 'advisors'){
                price = parseFloat(d.price.trim());
                if(isNaN(price) || price < 0){
                  toastController.create({position: "top", duration: 3000, message: t('errors.invalid_price'), color: "danger"}).then((t) => t.present())
                  return false
                }
              }
              postData.price      = price
              if(props.leadData.AL_id){
                postData.AL_id = props.leadData.AL_id
              }
              if(user.user_id){
                postData.user_id = user.user_id
              }
              if(user.id){
                postData.advisor_id = user.id
              }

              loading.present()
              const endpoint = {
                'is-admin': usersListType.value === 'advisors' ? 'leads/assign' : 'ap_agent/lead/assign',
                'is-ap-agent': 'appointment/assign',
              }
              axios.post(endPointByRole+'/'+endpoint[userRole.value], postData)
                  .then( resp => {
                    toast.message = resp.data.success ? t('lead_assign_success_msg', {user_type: t( usersListType.value === 'advisors' ? 'advisor' : 'appoinemtn agent' )}) : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    if( resp.data.success ){
                      emit('onLeadAssigned', resp.data.data.assigned_to)
                    }
                    toast.present()
                  })
                  .catch(error => {
                    toast.message = t('Error')+': '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          },
        ]
      })
      await iAlert.present()
    }

    // View an advisor last week assigned leads
    const viewAdvisorLastWeekLeadsCount = async (advisor, leads_type) => {

      leads_type = leads_type || 'leads';
      const assignedLeads = (leads_type === 'leads' ? advisor.assigned_leads.last_week: advisor.appointments.last_week)

      const iAlert = await alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        message: '',
        buttons: [
          {
            text: t('OK'),
            cssClass: isDesktop ? 'alert-btn-light' : '',
          }
        ]
      })
      iAlert.header = t('Last week '+leads_type);
      iAlert.message =  assignedLeads.total < 1 ? t('None') : assignedLeads.total+' '+t('Assigned').toLowerCase();
      await iAlert.present()
    }

    onMounted(()=>{
      getUsersForLeadAssignment()
    })

    watch(usersListType, ()=>{
      getUsersForLeadAssignment()
    })

    return{
      advisorsFilters,
      advisorsSkeleton,
      assignLead,
      filterAdvisors,
      pagination,
      paginationCallback,
      searchAdvisors,
      userRole,
      usersList,
      usersListType,
      viewAdvisorLastWeekLeadsCount,
    }
  }
}
</script>
