import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";

export default () => {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    /**
     * Add one or more leads to the marketplace
     * @param _leads array having leads (objects)
     * @param callback the call back to call when ajax is completed. arguments (success[true|false], marketplace ids, price)
     * @returns {Promise<void>}
     */
    const addToMarketplace = async ( _leads, callback ) => {

        const loading = await loadingController.create({message: i18n.t('Processing...')})
        const toast   = await toastController.create({position: "top", duration: 3000})

        let ids = []
        for(let x in _leads){
            ids.push( _leads[x].id)
        }

        let iAlert = await alertController.create({
            cssClass: 'text-lg-left '+(isDesktop ? 'alert-web' : ''),
            header: `${i18n.t('Add')} lead${ids.length > 1 ? 's' : ''} ${i18n.t('to marketplace')}`,
            message: i18n.t('Price')+' &euro;',
            inputs: [
                {
                    name: 'price',
                    type: 'text',
                    cssClass: 'mt-0 '+(isDesktop ? 'form-control' : ''),
                }
            ],
            buttons: [
                {
                    text: i18n.t('Cancel'),
                    cssClass: isDesktop ? 'mr-1 alert-btn-light' : '',
                },
                {
                    cssClass: isDesktop ? 'alert-btn-primary' : '',
                    text: i18n.t('Confirm'), handler: (d) => {

                        let price = parseFloat(d.price.trim());
                        if(!price || price < 1){
                            toastController.create({position: "top", duration: 3000, message: i18n.t('errors.invalid_price'), color: "danger"}).then((t) => t.present())
                            return false
                        }

                        loading.present()
                        axios.post('/api/admin/marketplace/add', {ids: JSON.stringify(ids), price: price})
                            .then( resp => {
                                toast.message = resp.data.success ? i18n.t('lead_added_to_marketplace_msg') : resp.data.message
                                toast.color   = resp.data.success ? "primary" : "danger"
                                toast.present()
                                callback(resp.data.success, resp.data.data, price)
                            })
                            .catch(error => {
                                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                                toast.color = "danger"
                                toast.present()
                                callback(false)
                            })
                            .then( () => loading.dismiss())
                    }
                },
            ]
        })
        await iAlert.present()
    }

    /**
     * Withdraw one or more leads from the marketplace
     * @param _leads array having leads (objects)
     * @param callback the call back to call when ajax is completed
     * @returns {Promise<void>}
     */
    const withdrawLeads = async ( _leads, callback ) => {

        const loading = await loadingController.create({message: i18n.t('Processing...')})
        const toast   = await toastController.create({position: "top", duration: 3000})

        let ids = []
        for(let x in _leads){
            ids.push( _leads[x].id)
        }

        const iAlert = await alertController.create({
            cssClass: isDesktop ? 'alert-web' : '',
            header: `${i18n.t('Withdraw')} lead${ids.length > 1 ? 's' : ''}`,
            message: i18n.t(ids.length > 1 ? 'marketplace.withdrawal_confirmation': 'marketplace.withdrawal_confirmation1'),
            buttons: [
                {
                    text: i18n.t('Cancel'),
                    cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                },
                {
                    cssClass: isDesktop ? 'alert-btn-warning' : '',
                    text: i18n.t('Confirm'), handler: () => {

                        loading.present()
                        axios.post('/api/admin/marketplace/withdraw', {ids: JSON.stringify(ids)})
                            .then( resp => {
                                toast.message = resp.data.success ? i18n.t('lead_withdraw_success_msg') : resp.data.message
                                toast.color   = resp.data.success ? "primary" : "danger"
                                toast.present()
                                callback(resp.data.success)
                            })
                            .catch(error => {
                                toast.message = i18n.t('Error')+': '+error.response.status+' '+error.response.statusText
                                toast.color = "danger"
                                toast.present()
                                callback(false)
                            })
                            .then( () => loading.dismiss())
                    }
                },
            ]
        })
        await iAlert.present()
    }

    return {
        addToMarketplace,
        withdrawLeads,
    }
}