<template>
  <special-table>
    <table-row is-heading>
      <table-col v-for="t in data.titles" :key="'lmtt'+t">{{t}}</table-col>
    </table-row>
    <table-row v-for="(y,i) in data.data[0].length" :key="'lmtd'+y">
      <table-col v-for="(x,ii) in data.titles.length" :key="'lmtds'+x"> {{ data.data[ii][i] }}</table-col>
    </table-row>
  </special-table>
</template>

<script>
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
export default {
  components: {TableCol, TableRow, SpecialTable},
  props:{
    data: {
      required: true,
      type: Object,
      default: ()=>{
        return{
          titles: [],
          data: [],
        }
      }
    }
  }
}
</script>
