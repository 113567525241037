<template>
  <card stretch>
    <card-header :title="$t('Mortgage Calculations')" class="mb-2"></card-header>
    <div v-if="!mortgage.data" class="d-flex align-center justify-center text-muted mt-5 py-5">
      <nio-icon icon="ni-info-i" class="mr-2"></nio-icon> {{ $t('Mortgage not calculated') }}.
    </div>
    <template v-else>

      <!-- default/legacy, shouldn't be removed though -->
      <n-k-statistics v-if="'default' === mortgage.calculator">
        <row>
          <template v-for="(mC,index) in mortgage.data" :key="'mC'+index">
            <column :md="12" :lg="6" v-if="['is-ap-agent', 'is-admin'].includes(userRole) || ['Lowest income', 'Highest income', 'Total income', 'Calculations Income', 'Max mortgage'].includes(mC.label)">
              <n-k-statistics-item :title="$t(mC.label)" :count="mC.data" :icon="{icon: mC.icon || 'ni-info-i', bg: mC.icon_color || 'danger'}"></n-k-statistics-item>
            </column>
          </template>
        </row>
      </n-k-statistics>
      <mortgage-simple-table v-else-if="['house_equity_eat_in_mortgage', 'making_home_more_sustainable', 'retirement_mortgage'].includes(mortgage.calculator)"
                             :data="mortgage.data"></mortgage-simple-table>
      <solar-panel-mortgage v-else-if="mortgage.calculator === 'solar_panel_mortgage'"
                            :data="mortgage.data"></solar-panel-mortgage>
      <max-mortgage v-else-if="'max_mortgage' === mortgage.calculator" :data="mortgage.data"></max-mortgage>
      <max-mortgage-v1 v-else-if="'max_mortgage_v1' === mortgage.calculator" :data="mortgage.data"></max-mortgage-v1>
      <div v-else class="text-warning">{{ $t('Calculator template not available.') }}</div>
    </template>
  </card>
</template>

<script>
import {Card, CardHeader} from "@core/components/cards";
import {NKStatistics, NKStatisticsItem} from "@core/components/nk-statistics";
import {Column, Row} from "@core/layouts";
import MortgageSimpleTable from "./MortgageSimpleTable";
import SolarPanelMortgage from "./SolarPanelMortgage";
import storeHelper from '@/store/helper'
import MaxMortgageV1 from "@/views/mortgage-calculations/MaxMortgageV1";
import MaxMortgage from "@/views/mortgage-calculations/MaxMortgage";
export default {
  components: {
    MaxMortgage,
    MaxMortgageV1, NKStatisticsItem, Column, Row, NKStatistics, CardHeader, Card,
    MortgageSimpleTable,
    SolarPanelMortgage,
  },
  props:{
    mortgage: {
      required: true,
      type: Object,
      default: ()=>{
        return {
          calculator: 'default',
          data: null,
        }
      }
    },
  },
  setup(){
    const{userRole} = storeHelper()
    return{
      userRole,
    }
  }
}
</script>
