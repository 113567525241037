<template>
  <row>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Purchase price') }}</div>
      <span>&euro;{{ data.purchase_price.amount }} ({{ data.purchase_price.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Renovation costs') }}</div>
      <span>&euro;{{ data.renovation_costs.amount }} ({{ data.renovation_costs.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Own money') }}</div>
      <span>&euro;{{ data.own_amount }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Transfer tax') }}</div>
      <span>&euro;{{ data.transfer_tax }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Notary fees') }}</div>
      <span>&euro;{{ data.notary_fees }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Buyer cost') }}</div>
      <span>&euro;{{ data.buyer_costs.amount }} ({{ data.buyer_costs.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Brokerage fees') }}</div>
      <span>&euro;{{ data.brokerage_fees }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Building inspection') }}</div>
      <span>&euro;{{ data.building_inspection }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Costs NHG') }}</div>
      <span>&euro;{{ data.costs_NHG }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Advice & brokerage costs') }}</div>
      <span>&euro;{{ data.advice_and_brokerage_costs }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Bank guarantee') }}</div>
      <span>&euro;{{ data.bank_guarantee }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Starter loan') }}</div>
      <span>&euro;{{ data.starter_loan }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Other costs') }}</div>
      <span>&euro;{{ data.other_costs.amount }} ({{ data.other_costs.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('You need') }}</div>
      <span>&euro;{{ data.you_need.amount }} ({{ data.you_need.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Maximum loan based on the purchase price') }}</div>
      <span>&euro;{{ data.max_loan_purchase_price.amount }} ({{ data.max_loan_purchase_price.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Required own money') }}</div>
      <span>&euro;{{ data.required_own_money.amount }} ({{ data.required_own_money.percentage }}%)</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Account income') }}</div>
      <span>&euro;{{ data.account_income }}</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Financing cost percentage') }}</div>
      <span>{{ data.financing_cost_percentage }}%</span>
    </column>
    <column lg="6" class="mb-2">
      <div class="fw-500">{{ $t('Maximum mortgage based on your joint income') }}</div>
      <span>&euro;{{ data.maximum_mortgage_based_on_your_joint_income }}</span>
    </column>
  </row>
</template>

<script>
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
export default {
  components: {Column, Row},
  props:{
    data: {
      required: true,
      type: Object,
    }
  }
}
</script>
