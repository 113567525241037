import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";
export default () => {
    const{t} = useI18n()
    const companiesForDropdown= ()=>{
        return new Promise((rs,rj)=>{
            axios.get('/api/company/list')
                .then(r=> r.data.success ? rs(r.data.data) : rj(r.data.message))
                .catch(er=> rj(er.response ? er.response.status+' '+er.response.statusText : t('errors.general')) )
        })
    }

    return{
        companiesForDropdown,
    }
}