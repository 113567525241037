<template>
  <ion-modal :is-open="isModalOpen" @didDismiss="closeModal()" :css-class="isDesktop ? 'modal-web' : ''">
    <i-modal :title="$t('Respond to lead return')" @modal-close="closeModal()">
      <p class="overline-title mb-1">{{ $t('Reason') }}</p>
      <p>{{ $t(leadData.reason || 'unknown') }}</p>
      <p class="overline-title mb-1">{{ $t('Explanation') }}</p>
      <p>{{ leadData.explanation }}</p>
      <hr class="border-top"/>
      <form-group>
        <label class="overline-title d-block">{{$t('Change status')}}</label>
        <b-radio name="new_return_status" id="nrs_1" label="Accept" value="1" v-model="adminResponse.new_status"/>
        <b-radio name="new_return_status" id="nr_2" label="Reject" value="2" v-model="adminResponse.new_status" class="ml-3"/>
      </form-group>
      <form-group>
        <label class="overline-title">{{$t('Reason')}}/{{$t('comment')}}</label>
        <textarea class="form-control" v-model="adminResponse.comment"></textarea>
      </form-group>
      <template v-slot:footer class="p-5">
        <nk-button type="primary" class="mr-2" v-on:click="submitReturnResponse">{{$t('Submit')}}</nk-button>
        <a href="javascript:;" class="link link-light" v-on:click="closeModal()">{{$t('Cancel')}}</a>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {IonModal, isPlatform, loadingController, toastController} from "@ionic/vue"
import IModal from "@core/components/ion-modal/IModal";
import {FormGroup} from "@core/layouts";
import {BRadio} from "@core/components/bp-form";
import {reactive} from "vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";

export default {
  components:{BRadio,FormGroup,IModal,IonModal},
  props: {
    isModalOpen: Boolean,
    leadData: Object,
  },
  emits:['closeModal', 'statusChange'],
  setup(props,{emit}){

    const {t} = useI18n()
    const isDesktop = isPlatform('desktop')
    let adminResponse = reactive({
      new_status: '',
      comment: '',
    })
    const closeModal = ()=>{
      emit('closeModal')
    }
    const submitReturnResponse = async () => {

      const loader = await loadingController.create({message: t('Please wait...')})
      await loader.present()

      const toast  = await toastController.create({duration: 4000})

      axios.post('/api/admin/leads/returned/respond', {return_id: props.leadData.return_id, status: adminResponse.new_status, comment: adminResponse.comment})
          .then( resp => {
            if(resp.data.success){
              toastController.create({duration:3000, message: t('Thank you! action completed'), color: "primary"}).then((t)=> t.present())
              emit('statusChange', adminResponse)
            }
            else{
              toastController.create({duration:3000, message: resp.data.message, color: "danger"}).then((t)=> t.present())
            }
          })
          .catch( error => {
            toast.message = t('Error')+': '+error.response.status+' '+error.response.statusText
            toast.color = 'danger'
            toast.present()
          })
          .then( () => loader.dismiss())
    }

    return{
      adminResponse,
      closeModal,
      isDesktop,
      submitReturnResponse,
    }
  }
}
</script>
