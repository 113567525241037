<template>
  <page-template>
    <main-block>
      <block-content>
        <nk-button type="primary" v-on:click="F_advisorsAndAvailability">Availability</nk-button>
        <card class="px-2 py-1 mb-3" no-padding>
          <div class="d-flex justify-between align-items-center fw-500 nk-text-dark h6">
            <div>
              <nk-button type="" is-icon-only-button="" round v-on:click="router.go(-1)" class="btn-trigger mr-3">
                <nio-icon icon="ni-arrow-left"></nio-icon>
              </nk-button>
              <span>{{$t('Lead Details')}}</span>
            </div>
            <nk-button type="primary" outline size="sm" v-if="lead_details.analytics_id" v-on:click="$router.push({name: 'adminLeadAnalytics', params:{id: lead_details.id}})">
              <nio-icon icon="ni-growth-fill mr-1"></nio-icon> {{$t('Analytics')}}
            </nk-button>
          </div>
        </card>
        <div v-if="leadDetailsError.length">
          <alert color="danger">{{leadDetailsError}}</alert>
        </div>
        <row v-else class="g-gs">
          <column lg="6" xxl="5">
            <alert v-if="lead_details.isAppointment" color="primary" class="fw-500">{{ $t('pages.admin_lead_details.lead_is_now_appointment')}}</alert>
            <row>
              <column :md="5">
                <b class="d-block text-primary">Lead #{{lead_details.lead_number}}</b>
                <badge v-if="lead_details.form_name" type="gray" style="font-size: 11px;">{{ $t('Form')+': '+ lead_details.form_name}}</badge>
                <star-rating
                    v-if="parseFloat(lead_details.assigned_to.advisor_info.rating) > 0"
                    :rating="lead_details.assigned_to.advisor_info.rating"
                    :increment="0.5" :star-size="12"
                    :show-rating="false"
                    :read-only="true"></star-rating>
              </column>
              <column :md="7" class="mt-2 mt-md-0">
                <div class="nk-text-muted">{{ $t('Received')}}:</div>
                <span class="form-control">{{ $t('on') }}: <b>{{ lead_details.received_date}}</b>, {{$t('at')}}: <b>{{ lead_details.received_time}}</b></span>
              </column>
            </row>
            <row class="mt-2">
              <column :md="5">
                <ion-skeleton-text v-if="!lead_details.id" animated class="w-100" style="height: 100px;"></ion-skeleton-text>
                <template v-else>
                  <span class="d-block nk-text-dark fw-500 lh-n mb-2">{{lead_details.first_name+' '+lead_details.last_name}}</span>
                  <div class="lh-n nk-text-muted mb-2">
                    <div class="d-flex align-items-center">
                      <nio-icon icon="ni-call" class="mr-1"></nio-icon> {{lead_details.phone_no}}
                    </div>
                    <div class="d-flex align-items-center">
                      <nio-icon icon="ni-mail" class="mr-1"></nio-icon> {{lead_details.email}}
                    </div>
                  </div>
                  <div class="nk-text-muted">{{ lead_details.house_no+', '+lead_details.street }}<br/>{{ lead_details.post_code}}, {{lead_details.city+' '+lead_details.province}}</div>
                </template>
              </column>
              <column :md="7">
                <row class="no-gutters text-center lh-n">
                  <column default="6" class="pr-1">
                    <card class="bg-success p-1 text-dark" no-padding>
                      <div class="fw-500">{{ $t('Lead Score') }}</div>
                      <div style="font-size: 2.1rem" class="my-1">{{ lead_details.lead_score }}</div>
                      <div>{{ $t('High') }}</div>
                    </card>
                  </column>
                  <column default="6" class="pl-1">
                    <card class="bg-warning p-1 text-dark" no-padding>
                      <div class="fw-500">{{ $t('Engagement') }}</div>
                      <div style="font-size: 2.1rem" class="my-1">2,5</div>
                      <div>{{ $t('Low') }}</div>
                    </card>
                  </column>
                </row>
              </column>
            </row>
            <lead-data :lead_data="lead_details.lead_data.length ? lead_details.lead_data : lead_details"></lead-data>
            <row class="mt-2">
              <column default="12" class="text-primary fw-500" v-if="lead_details.marketplace.id > 0">
                <span v-if="lead_details.marketplace.status === 1">{{$t('marketplace.lead_sold_msg')}}.</span>
                <span v-else-if="lead_details.marketplace.status === 2">{{$t('marketplace.lead_booked_msg')}}.</span>
                <span v-else>{{$t('marketplace.lead_is_in_marketplace')}}.</span>
                <span> {{$t('For')}} &euro;{{ lead_details.marketplace.price }}.</span>
              </column>
              <column default="6">
                <nk-button type="dark" v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.advisor_info.id && !lead_details.assigned_to.ap_agent_info.id"
                           v-on:click="addLeadToMarketplace">{{$t('To marketplace')}}</nk-button>
                <nk-button type="dark" v-else-if="!lead_details.assigned_to.advisor_info.id && !lead_details.assigned_to.ap_agent_info.id && ![1,2].includes(lead_details.marketplace.status)"
                           v-on:click="withdrawLead">{{ $t('Withdraw') }}</nk-button>
              </column>
              <column default="6" class="text-right" v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.advisor_info.id && !lead_details.assigned_to.ap_agent_info.id">
                <nk-button type="danger" v-on:click="deleteLead">{{ $t('Delete') }}</nk-button>
              </column>
            </row>
          </column>

          <column :lg="6" :xxl="7">
            <lead-mortgage-calculations :mortgage="lead_details.mortgage_calculations"></lead-mortgage-calculations>
          </column>

          <column xxl="12">
            <card :full-height="true" no-padding stretch>
              <accordion id="lead_map" class="is-separate bg-transparent">
                <accordion-item class="light-shadow mb-0" id="acc_lead_map">
                  <accordion-item-head data-target="#lead_map_content" :collapsed="!isDesktop">
                    <div class="d-flex align-items-center">
                      <nio-icon icon="ni-map" class="mr-2 text-primary" style="font-size: 1.5rem"></nio-icon>
                      <div class="flex-fill">{{ $t('View on map') }}</div>
                    </div>
                  </accordion-item-head>
                  <accordion-item-body id="lead_map_content" data-parent="#acc_lead_map" no-padding class="border-top" :collapsed="!isDesktop">
                    <div class="p-4">
                      <row class="nk-text-dark">
                        <column :md="5">
                          <div class="font-weight-bold">{{ lead_details.house_no+', '+lead_details.street }}</div>
                          <div class="nk-text-muted lh-n">{{ lead_details.post_code }}, {{ lead_details.city }} {{ lead_details.province }}</div>
                        </column>
                      </row>
                    </div>
                    <lead-location-map
                        map-type="roadmap"
                        :start="lead_details.post_code+' '+lead_details.house_no+','+lead_details.city"
                        end="" height="400px"></lead-location-map>
                  </accordion-item-body>
                </accordion-item>
              </accordion>
            </card>
          </column>

          <!-- lead on map -->
          <column :xxl="12" class="d-none">

          </column>

          <column :xxl="12">
            <lead-assignment-list v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.advisor_info.id && !lead_details.assigned_to.ap_agent_info.id"
                                  :lead-data="lead_details"
                                  @on-lead-assigned="onLeadAssigned">

            </lead-assignment-list>
            <lead-assignment-info v-else-if="lead_details.assigned_to.advisor_info.id || lead_details.assigned_to.ap_agent_info.id"
                                  :assignment-info="leadAssignment">

            </lead-assignment-info>
          </column>

          <column lg="6" v-if="lead_details.followup_logs.length">
            <followup-logs :followup-logs="lead_details.followup_logs"></followup-logs>
          </column>

        </row>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {
  IonSkeletonText,
  isPlatform, modalController, onIonViewWillEnter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card } from '@core/components/cards'

import "bootstrap"

import LeadData from "@/views/lead-details/LeadData";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemHead} from "@core/components/accordion"
import LeadLocationMap from "@/views/lead-details/LeadLocationMap";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import useMarketplace from "./useMarketplace";
import useDeleteLeads from "./useDeleteLeads";

import axios from "@/libs/axios";
import { useRoute } from "vue-router"
import { useRouter} from "vue-router"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import {useI18n} from "vue-i18n";
import StarRating from "vue-star-rating";
import useCommonFunc from "@core/comp-functions/common"
import Badge from "@core/components/badge/Badge";
import Alert from "@core/components/alert/Alert";
import LeadAssignmentList from "@/views/admin/Leads/components/LeadAssignmentList";
import LeadAssignmentInfo from "@/views/admin/Leads/components/LeadAssignmentInfo";
import FollowupLogs from "@/views/lead-details/components/FollowupLogs";
import AdvisorsAndAvailability from "@/views/admin/Leads/components/AdvisorsAndAvailability";
import LeadMortgageCalculations from "@/views/mortgage-calculations/LeadMortgageCalculations";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    LeadMortgageCalculations,
    FollowupLogs,
    LeadAssignmentInfo,
    LeadAssignmentList,
    Alert,
    Badge,
    NkButton,
    NioIcon,
    LeadLocationMap,
    AccordionItemBody,
    AccordionItemHead,
    AccordionItem,
    Accordion,
    LeadData,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    IonSkeletonText,
    StarRating,
    /*IonHeader,
    IonTitle,
    IonToolbar,*/
  },
  setup() {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const route  = useRoute()
    const router = useRouter()
    const { updateTracker } = useDataChangeTracker()
    const { updateObject } = useCommonFunc()

    // Lead related logic

    let lead_details    = reactive({
          analytics_id: '',
          assigned_to: {
            advisor_info: {
              assign_date: '',
              assign_date_unix: 0,
              assigned_by: 0,
              dp: '',
              email: '',
              first_name: '',
              id: false,
              initials: '',
              last_name: '',
              profile_id: 0,
              rating: 0,
              return_info:{},
              returned: 0,
              status_color: '',
              status_text: '',
              type: 'advisor',
            },
            ap_agent_info:{
              assign_date: '',
              assign_date_unix: 0,
              dp: '',
              first_name: '',
              id: 0,
              initials: '',
              last_name: '',
              profile_id: 0,
              status_text: '',
              type: 'ap_agent',
            }
          },
          followup_logs: [],
          house_no: '',
          status_id: 0,
          event_date_iso: '',
          event_date: '',
          first_name: '',
          id: 0,
          isAppointment: false,
          last_name: '',
          lead_data:[],
          marketplace: {
            id: 0,
            price: '',
            status: 0,
          },
          mortgage_calculations: {
            calculator: '',
            data: null,
          },
          notes: [],
          city: '',
          post_code: '',
          province: '',
          return_info: {
            admin_comment: '',
            explanation: '',
            reason: '',
            reason_id: 0,
            return_status: 0,
          },
          street: '',
        })
    let leadDetailsError = ref('')
    // Get lead details from the server
    const getLeadDetails = async () => {
      leadDetailsError.value = ''
      axios.get('/api/admin/leads/details/'+route.params.id)
          .then( (resp) => {
            if(!resp.data.success){
              leadDetailsError.value = resp.data.message
            }
            else{
              updateObject(lead_details, resp.data.data)
            }
          })
          .catch( err => {
            leadDetailsError.value = err.response ? err.response.status+' '+err.response.statusText : i18n.t('errors.general_error')
          })
    }
    // Adding and withdrawing lead from the marketplace
    const { addToMarketplace, withdrawLeads } = useMarketplace()
    const withdrawLead = () => {
      withdrawLeads([lead_details], (success) => {
        if( success ){
          updateTracker('admin', ['marketplace'])
          lead_details.marketplace.id = 0
        }
      })
    }
    const addLeadToMarketplace = () => {
      addToMarketplace([lead_details], (success, marketplaceIds, price ) => {
        if(success){
          updateTracker('admin', ['leads_list', 'marketplace'])
          lead_details.marketplace.id = marketplaceIds[0]
          lead_details.marketplace.price = price
        }
      })
    }
    // Deleting the lead
    const { deleteRecords } = useDeleteLeads()
    const deleteLead = () => {
      deleteRecords([lead_details], 'lead',(success) => {
        if(success){
          updateTracker('admin', ['assigned_leads', 'leads_list', 'marketplace'])
          router.go(-1)
        }
      })
    }

    onIonViewWillEnter(()=>{
      getLeadDetails()
    })

    const leadAssignment = computed(()=>{
      let data = [lead_details.assigned_to.advisor_info, lead_details.assigned_to.ap_agent_info]
      data.sort((a,b) => a.assign_date_unix - b.assign_date_unix);
      return data
    })

    const onLeadAssigned = (data)=>{
      updateObject(lead_details.assigned_to, data)
    }

    const F_advisorsAndAvailability = async () => {
      const m = await modalController.create({
        component: AdvisorsAndAvailability,
        componentProps:{
          leadId: route.params.id
        },
        cssClass: 'advisors_and_availability_modal'+(isDesktop ? ' modal-web' : ''),
      })
      await m.present()
    }

    return {
      addLeadToMarketplace,
      deleteLead,
      F_advisorsAndAvailability,
      isDesktop,
      leadAssignment,
      lead_details,
      leadDetailsError,
      onLeadAssigned,
      router,
      withdrawLead,
    }
  }
});
</script>
