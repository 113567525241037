import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";

export default () => {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const deleteRecords = async ( _records, record_type, callback ) => {

        const loading = await loadingController.create({message: i18n.t('Processing...')})
        const toast   = await toastController.create({position: "top", duration: 3000})

        let ids = []
        for(let x in _records){
            ids.push( _records[x].id)
        }

        let iAlert = await alertController.create({
            cssClass: isDesktop ? 'alert-web' : '',
            header: `Delete ${record_type+(_records.length > 1 ? 's' : '')}`,
            message: i18n.t('action_confirmation', {action: i18n.t('delete'), count: _records.length, type: i18n.t(record_type)})+(_records.length > 1 ? ' s': ''),
            buttons: [
                {
                    text: i18n.t('Cancel'),
                    cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                },
                {
                    cssClass: isDesktop ? 'alert-btn-danger' : 'text-danger',
                    text: i18n.t('Confirm'), handler: () => {
                        loading.present()
                        axios.post('/api/admin/leads/delete', {ids: JSON.stringify(ids)})
                            .then( resp => {
                                toast.message = resp.data.success ? i18n.t('Thank you! action completed') : resp.data.message
                                toast.color   = resp.data.success ? "primary" : "danger"
                                toast.present()
                                callback(resp.data.success)
                            })
                            .catch(error => {
                                toast.message = i18n.t('Error')+': '+error.response.status+' '+error.response.statusText
                                toast.color = "danger"
                                toast.present()
                                callback(false)
                            })
                            .then( () => loading.dismiss())
                    }
                },
            ]
        })
        await iAlert.present()
    }

    return {
        deleteRecords,
    }
}