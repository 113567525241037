<template>
  <div class="map" ref="mapDivRef" :style="{height: height}"></div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import { Loader } from "@googlemaps/js-api-loader"
import {useI18n} from "vue-i18n";

export default {
  props: {
    center: { lat: Number, lng: Number },
    zoom: Number,
    mapType: String,
    disableUI: Boolean,
    start: String,
    end: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '300px',
    }
  },
  emits: ['distanceChanged'],
  setup(props, {emit}) {

    const{t} = useI18n()
    const mapLoader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLEMAPS_KEY,
      version: "weekly",
      libraries: []
    });

    const mapOptions = {
      center: {
        lat: 52.1950004,
        lng: 3.0314304,
      },
      zoom: 4,
      mapType: props.mapType,
      disableDefaultUI: true,
    }

    // the map element in the template
    const mapDivRef = ref(null);
    let directionService = ref(null)
    let directionRenderer = ref(null)
    let distance = ref('')

    const renderMap = () => {
      mapLoader.load()
          .then((google) => {
            const icons = mapIcons(google)

            directionService.value = new google.maps.DirectionsService()
            directionRenderer.value = new google.maps.DirectionsRenderer({
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#11c694"
              },
            });
            let map = new google.maps.Map(mapDivRef.value, mapOptions);
            directionRenderer.value.setMap(map)

            if(!props.end || !props.end.length){
              let geocoder = new google.maps.Geocoder()
              geocoder.geocode( {'address': props.start }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
                    map.setCenter(results[0].geometry.location);
                    map.setZoom(12)
                    let infoWindow = new google.maps.InfoWindow({
                      content: '<b>' + props.start + '</b>',
                      size: new google.maps.Size(150, 50)
                    })
                    let marker = new google.maps.Marker({
                      position: results[0].geometry.location,
                      map: map,
                      title: props.start,
                    })
                    google.maps.event.addListener(marker, 'click', function() {
                      infoWindow.open(map, marker);
                    });

                  }
                }
              })
              return true
            }

            directionService.value.route({origin: props.start, destination: props.end, travelMode: "DRIVING"}, function(result, status) {
              if (status === 'OK') {
                directionRenderer.value.setDirections(result);
                let leg = result.routes[ 0 ].legs[ 0 ];
                distance.value = (leg.distance.value / 1000).toFixed()
                //makeMarker( google, map, leg.start_location, icons.start, "title" );
                makeMarker( google, map, leg.end_location, icons.end, 'Lead '+t('Location') );
              }
            });
          })
          .catch(e => {
            console.log('map error: '+e)
          });
    }

    watch(() => props.end, () => renderMap())
    watch(() => props.start, ()=> renderMap())
    onMounted(()=> renderMap())

    // Start/Finish icons
    const mapIcons = (google) => {
      return  {
        start: new google.maps.MarkerImage(
            require('@/assets/images/map-user.png'),
            // (width,height)
            new google.maps.Size( 44, 32 ),
            // The origin point (x,y)
            new google.maps.Point( 0, 0 ),
            // The anchor point (x,y)
            new google.maps.Point( 22, 32 )
        ),
        end: new google.maps.MarkerImage(
            require('@/assets/images/map-user.png'),
            // (width,height)
            new google.maps.Size( 64, 64 ),
            // The origin point (x,y)
            new google.maps.Point( 0, 0 ),
            // The anchor point (x,y)
            new google.maps.Point( 22, -2 )
        )
      }
    }

    const makeMarker = ( google, map, position, icon, title ) => {
      new google.maps.Marker({
        position: position,
        map: map,
        icon: icon,
        title: title
      });
    }

    watch(distance, (newVal) => {
      emit('distanceChanged', newVal)
    })

    return {
      mapDivRef,
      distance,
    }
  }
};
</script>

<style lang="css" scoped>
.map {
  width: 100%;
  background-color: azure;
}
</style>
