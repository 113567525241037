<template>
  <i-modal :title="$t('Advisors availability')">
    <row class="g-gs">
      <column default="12" :lg="3">
        <card stretch>
          <div class="fw-500 overline-title mb-1">{{ $t('Company filter') }}</div>
          <b-select id="av_com_list" v-model="companyFilter" size="sm">
            <option value="0">{{ $t('All') }}</option>
            <option value="-1">{{ $t('Non-company advisors') }}</option>
            <option v-for="c in companies" :value="c.id" :key="'ccl'+c.id">{{c.company_name}}</option>
          </b-select>
          <div class="fw-500 overline-title mt-2 mb-1" v-if="leadId">{{ $t('Distance from lead') }}</div>
          <b-select id="av_d_filter" v-model="distanceFilter" size="sm">
            <option value="0">{{ $t('Any') }}</option>
            <option v-for="x in [20,15,10,8,6,5,4,3,2]" :value="x" :key="'tmp'+x"> &lt; {{ x }} KM</option>
          </b-select>
          <h6 class="mt-2">{{ $t('Advisors')}}</h6>
          <ul style="max-height: 400px;overflow: hidden auto;">
            <li v-for="u in users" :key="'adv'+u.id">
              <div class="d-flex align-items-center border-bottom p-1 mb-1 rounded bg-secondary-dim" :class="{'text-muted': !u.selected}">
                <div class="user-avatar mr-1">
                  <router-link :to="routeToUserProfile(u)" target="_blank" class="text-white">
                    <img v-if="u.dp" :src="u.dp" alt="Avatar"/>
                    <span v-else class="text-uppercase">{{ u.initials }}</span>
                  </router-link>
                </div>
                <div class="flex-grow-1">
                  <div class="">
                    <router-link :to="routeToUserProfile(u)" target="_blank" class="text-dark">{{u.first_name}}</router-link>
                  </div>
                  <div class="small lh-n text-muted">
                    <div>{{u.google_matrix.distance.text}}</div>
                    <div>{{ $t('Leads')}}: {{ u.assigned_leads.this_week.total+'/'+u.available_leads}}</div>
                  </div>
                </div>
                <div class="pr-2">
                  <b-check-box v-model="u.selected" :id="'adv'+u.id"></b-check-box>
                </div>
              </div>
            </li>
          </ul>
        </card>
      </column>
      <column default="12" :lg="9">
        <card stretch>
          <FullCalendar ref="calendarRef" :options="calendarOptions"></FullCalendar>
        </card>
      </column>
    </row>
  </i-modal>
</template>

<script>
import Column from "@core/layouts/col/Col";
import Row from "@core/layouts/row/Row";
import Card from "@core/components/cards/Card";
import FullCalendar from "@fullcalendar/vue3"
import {onMounted, reactive, ref, watch} from "vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import nlLocal from "@fullcalendar/core/locales/nl";
import axios from "@/libs/axios";
import storeHelper from "@/store/helper"
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import {useI18n} from "vue-i18n";
import useAdminCompanyFunc from "@core/comp-functions/admin/Company"
import BSelect from "@core/components/bp-form/components/BSelect";
import IModal from "@core/components/ion-modal/IModal";
import {loadingController} from "@ionic/vue";

export default {
  components: {IModal, BSelect, BCheckBox, Card, Row, Column, FullCalendar },
  props:{
    leadId:{
      required: true,
    }
  },
  setup( props ){

    let users           = ref([])
    let filteredEvents  = ref([])
    let companies       = ref([])
    let companyFilter   = ref(0)
    let distanceFilter  = ref(0)
    let startDate       = ref(null)
    let endDate         = ref(null)
    let currentViewType = ref('dayGridWeek')

    const{t} = useI18n()
    const calendarRef  = ref(null)
    const{userRole} = storeHelper()
    const{companiesForDropdown}     = useAdminCompanyFunc()

    let calendarOptions = reactive({
      plugins: [dayGridPlugin, timeGridPlugin, listGridPlugin, interactionPlugin],
      initialView: 'dayGridWeek',
      views: {
        dayGridMonth: {
          dayMaxEvents: 3,
        }
      },
      weekends: false,
      height: 800,
      contentHeight: "auto",
      headerToolbar: {
        left: 'title prevCustom,nextCustom',
        center: null,
        right: 'today dayGridMonth,dayGridWeek prevCustom,nextCustom'
      },
      locales:[nlLocal],
      locale: (!localStorage.getItem('lang') ? 'nl' : localStorage.getItem('lang')),
      bootstrapFontAwesome: false,
      themeSystem: 'bootstrap',
      datesSet: function(info){
        if(info.view.type !== currentViewType.value){
          currentViewType.value = info.view.type
          startDate.value = info.startStr.substring(0,10)
          endDate.value = info.endStr.substring(0,10)
          getUsersAndAvailability()
        }
      },
      events: filteredEvents,
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      displayEventEnd: true,
      eventDisplay: 'block',
      customButtons: {
        prevCustom: {
          text: '',
          icon: 'chevron-left',
          click: ()=> navigateCal('prev')
        },
        nextCustom: {
          text: '',
          icon: 'chevron-right',
          click: ()=> navigateCal('next')
        },
      },
      viewDidMount: function (){
        calendarRef.value.getApi().el.querySelector('.fc-today-button').addEventListener('click', function() {
          navigateCal()
        });
      }
    })

    const navigateCal = (dir)=>{
      const calendarAPI = calendarRef.value.getApi()
      dir === 'next' ? calendarAPI.next() : (dir === 'prev' ? calendarAPI.prev() : '')
      startDate.value = calendarAPI.getCurrentData().dateProfile.activeRange.start.toISOString().substring(0, 10)
      endDate.value = calendarAPI.getCurrentData().dateProfile.activeRange.end.toISOString().substring(0, 10)
      getUsersAndAvailability(false)
    }

    const getUsersAndAvailability = async (reset) => {
      if(reset){
        users.value.splice(0)
      }
      let loader = await loadingController.create({message: 'Please wait...'})
      await loader.present()
      const d = {
        lead_id: props.leadId,
        availability_from: startDate.value,
        availability_to: endDate.value,
        company_filter: companyFilter.value,
        distance_filter: distanceFilter.value,
      }
      axios.post('/api/lead_assignment/users_and_availability', d)
          .then(resp => {
            resp = resp.data
            if(!resp.success){
              console.log(resp.message)
            }
            else{
              for(let x in resp.data.records){
                if(reset){
                  resp.data.records[x].selected = true
                  users.value.push( resp.data.records[x])
                }
                else{
                  users.value[x].calendar = resp.data.records[x].calendar
                }
              }
              filterEvents()
            }
          })
          .catch((er)=>{
            console.log(er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error'))
          })
          .then(()=> loader.dismiss())
    }

    const filterEvents = () => {
      filteredEvents.value.splice(0)
      for(let x in users.value){
        if(users.value[x].selected){
          filteredEvents.value = filteredEvents.value.concat(users.value[x].calendar)
        }
      }
    }

    onMounted(()=> {
      getUsersAndAvailability(true)
      companiesForDropdown()
          .then(d=>{
            companies.value.splice(0)
            for(let x in d){ companies.value.push(d[x])}
          })
          .catch(()=>{})
    })

    watch(users.value, ()=>{
      filterEvents()
    })
    watch(companyFilter,()=> getUsersAndAvailability(true))
    watch(distanceFilter,()=> getUsersAndAvailability(true))
    watch(()=> props.leadId, (n)=>{
      if(n){
        getUsersAndAvailability(true)
      }
    })

    const routeToUserProfile = (u) => {
      return {name: (userRole.value === 'is-ap-agent' ? 'apAgentAdvisorProfile' : 'advisorProfile'), params:{id: u.id}}
    }


    return {
      calendarOptions,
      calendarRef,
      companyFilter,
      companies,
      distanceFilter,
      filteredEvents,
      routeToUserProfile,
      users,
    }
  }
}
</script>

<style>
.fc-h-event .fc-event-main-frame{
  display: block;
}
.advisors_and_availability_modal{
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}
</style>
