<template>
  <div class="nk-store-statistics">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NKStatistics"
}
</script>
