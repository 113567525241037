<template>
  <div class="item">
    <div class="info">
      <div class="title">{{ title }}</div>
      <div class="count">{{ count }}</div>
    </div>
    <nio-icon v-if="icon.icon" :icon="icon.icon" :class="'bg-'+icon.bg+'-dim'"></nio-icon>
  </div>
</template>

<script>
export default {
  name: "NKStatisticsItem",
  props:{
    title: String,
    count: String,
    icon:{
      type: Object,
      default: () => {
        return {
          icon: '',
          bg: ''
        }
      }
    }
  },
}
</script>
