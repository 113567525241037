<template>
  <list-table>
    <tb-row head-row>
      <tb-th colspan="4">Rendementsberekening</tb-th>
    </tb-row>
    <tb-row>
      <tb-th>h.</tb-th>
      <tb-td>Maximale capaciteit zonnepanelen	</tb-td>
      <tb-td>{{ data.maximale_capaciteit_zonnepanelen }}</tb-td>
      <tb-td>kWh</tb-td>
    </tb-row>
    <tb-row>
      <tb-th>i.</tb-th>
      <tb-td>Gemiddelde rendement per jaar (25 jaar)</tb-td>
      <tb-td>{{ data.gemiddelde_rendement }}</tb-td>
      <tb-td>kWh</tb-td>
    </tb-row>
    <tb-row>
      <tb-th>j.</tb-th>
      <tb-td>Gemiddelde besparing (12 jaar)	</tb-td>
      <tb-td>{{ data.gemiddelde_besparing }}</tb-td>
      <tb-td>p.jr.</tb-td>
    </tb-row>
    <tb-row>
      <tb-th>k.</tb-th>
      <tb-td>Terugverdientijd in jaren</tb-td>
      <tb-td>{{ data.terugverdientijd_in_jaren }}</tb-td>
      <tb-td>jaar</tb-td>
    </tb-row>
    <tb-row>
      <tb-th colspan="2">Rendement</tb-th>
      <tb-td>{{ data.rendement }}</tb-td>
      <tb-td>pc</tb-td>
    </tb-row>
  </list-table>
  <list-table>
    <tb-row>
      <tb-th>Jaar</tb-th><tb-th colspan="2">Opbrengst in kWh</tb-th><tb-th>Tarief per kWh</tb-th>
      <tb-th>Besparing</tb-th><tb-th>Cum. Besparing</tb-th>
    </tb-row>
    <tb-row v-for="(y,i) in data.colC.length" :key="'lmtd'+y">
      <tb-td>{{ data.colC[i] }}</tb-td>
      <tb-td>{{ data.colD[i] }}</tb-td>
      <tb-td>{{ data.colE[i] }}</tb-td>
      <tb-td>{{ data.colG[i] }}</tb-td>
      <tb-td>{{ data.colI[i] }}</tb-td>
      <tb-td>{{ data.colJ[i] }}</tb-td>
    </tb-row>
  </list-table>
</template>

<script>
import ListTable from "@core/components/list-table/ListTable";
import {TbRow, TbTd, TbTh} from "@core/components/data-tables";
export default {
  components: {TbTd, TbTh, TbRow, ListTable},
  props:{
    data: {
      required: true,
      type: Object,
      default: ()=>{
        return{
          colC:[], colD:[], colE:[], colG: [], colI:[], colJ:[],
          maximale_capaciteit_zonnepanelen: '',
          gemiddelde_rendement: '',
          gemiddelde_besparing: '',
          terugverdientijd_in_jaren: '',
          rendement: '',
        }
      }
    }
  }
}
</script>
