<template>
  <row>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Lowest income') }}</span>
      <span>&euro;{{ data.lowest_income }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Highest income') }}</span>
      <span>&euro;{{ data.highest_income }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Total income') }}</span>
      <span>&euro;{{ data.total_income }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Calculations income') }}</span>
      <span>&euro;{{ data.calculation_income }}</span>
    </column>
    <column lg="12" class="mb-2">
      <span class="d-block fw-500">{{ $t('Installment amount used for Box3 Calculation') }}</span>
      <span>&euro;{{ data.installment_box3 }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Annuity factor box3') }}</span>
      <span>{{ data.annuity_factor_box3 }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Periodic payment box3') }}</span>
      <span>{{ data.periodic_payment_box3 }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Borrowed sum box3') }}</span>
      <span>{{ data.borrowed_sum_box3 }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Max ruimte allowed') }}</span>
      <span>{{ data.max_ruimte_allowed }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Max ruimte applied') }}</span>
      <span>{{ data.max_ruimte_applied }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Installment amount') }}</span>
      <span>{{ data.installment_amount }}</span>
    </column>
    <column lg="6" class="mb-2">
      <span class="d-block fw-500">{{ $t('Max mortgage') }}</span>
      <span>{{ data.max_mortgage }}</span>
    </column>
  </row>
</template>

<script>
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
export default {
  components: {Column, Row},
  props:{
    data: {
      required: true,
      type: Object,
    }
  }
}
</script>
