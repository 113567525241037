<template>
  <card class="shadow-none mt-4 bg-transparent" :no-padding="true">
    <list-table separated="" class="nk-text-muted" id="lead_details_table">
      <template v-if="lead_data instanceof Array">
        <template v-for="(ed,index) in lead_data" :key="'ed'+ed.id">
          <!-- we will be displaying only 10 by default, other will be folded -->
          <tb-row v-show="index < 7 || displayExtras">
            <tb-td>
              <div class="d-flex align-items-center">
                <nio-icon class="ld_icon" :icon="ed.field?.icon || 'ni-forward-arrow'"></nio-icon>
                <div class="lh-n ml-2">
                  <div>{{ $t( (ed.field) ? ed.field.label : (ed.label ? ed.label.label : (ed.extra_label || 'Unknown')) )}}</div>
                  <div class="text-muted">{{ ed.data }}</div>
                </div>
              </div>

            </tb-td>
          </tb-row>
        </template>
      </template>
      <template v-else>
        <tb-row>
          <tb-th>
            <nio-icon icon="ni-user"></nio-icon> {{ $t('Name')}}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.first_name+' '+lead_data.last_name }}</template>
          </tb-td>
        </tb-row>
        <tb-row>
          <tb-th>
            <nio-icon icon="ni-calendar-alt"></nio-icon> {{ $t('Age') }}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.age }}</template>
          </tb-td>
        </tb-row>
        <tb-row>
          <tb-th>
            <nio-icon icon="ni-briefcase"></nio-icon> {{ $t('Work')}}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.employment_type }}</template>
          </tb-td>
        </tb-row>
        <tb-row>
          <tb-th>
            <nio-icon icon="ni-sign-dollar"></nio-icon> {{ $t('Income')}}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.income }}</template>
          </tb-td>
        </tb-row>
        <tb-row>
          <tb-th>
            <nio-icon icon="ni-users"></nio-icon> {{$t('Partner')}}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.has_partner ? $t('Yes') : $t('No') }}</template>
          </tb-td>
        </tb-row>
        <tb-row v-if="lead_data.has_partner">
          <tb-th>
            <nio-icon icon="ni-sign-dollar"></nio-icon> {{$t('Partner')}} {{ $t('Income')}}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.partner_income }}</template>
          </tb-td>
        </tb-row>
        <tb-row v-if="lead_data.has_partner">
          <tb-th>
            <nio-icon icon="ni-calendar-alt"></nio-icon> Partner {{ $t('Age')}}
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.partner_age }}</template>
          </tb-td>
        </tb-row>
        <tb-row v-if="parseFloat(lead_data.total_debt) > 0">
          <tb-th>
            <nio-icon icon="ni-cc"></nio-icon> BKR
          </tb-th>
          <tb-td>
            <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
            <template v-else>{{ lead_data.total_debt }}</template>
          </tb-td>
        </tb-row>
        <template v-if="displayExtras">
          <tb-row>
            <tb-th>
              <nio-icon icon="ni-info"></nio-icon> {{ $t('Interest') }}
            </tb-th>
            <tb-td>
              <ion-skeleton-text v-if="lead_data.id < 1" animated class="w-100"></ion-skeleton-text>
              <template v-else>{{ lead_data.form_name }}</template>
            </tb-td>
          </tb-row>
          <tb-row v-if="parseFloat(lead_data.own_amount) > 0">
            <tb-th>
              <nio-icon icon="ni-coin-eur"></nio-icon> {{ $t('Own amount')}}
            </tb-th>
            <tb-td>
              &euro;{{ lead_data.own_amount }}
            </tb-td>
          </tb-row>
          <tb-row v-if="parseFloat(lead_data.college_debt) > 0">
            <tb-th>
              <nio-icon icon="ni-trend-down"></nio-icon> {{ $t('College')+' '+$t('Debt') }}
            </tb-th>
            <tb-td>
              &euro;{{ lead_data.college_debt }}
            </tb-td>
          </tb-row>
          <tb-row v-if="parseFloat(lead_data.other_debt) > 0">
            <tb-th>
              <nio-icon icon="ni-trend-down"></nio-icon> {{ $t('Other')+' '+$t('Debt') }}
            </tb-th>
            <tb-td>&euro;{{ lead_data.other_debt }}</tb-td>
          </tb-row>
          <tb-row v-if="lead_data.alimony && parseInt(lead_data.alimony) > 0">
            <tb-th>
              <nio-icon icon="ni-coins"></nio-icon> {{ $t('Alimony') }}
            </tb-th>
            <tb-td>{{ parseInt(lead_data.alimony) === 1 ? $t('Receive') : $t('Pay') }}</tb-td>
          </tb-row>
          <tb-row v-if="lead_data.alimony && parseInt(lead_data.alimony) > 0">
            <tb-th>
              <nio-icon icon="ni-coins"></nio-icon> {{ $t('Alimony')+' '+$t('Amount') }}
            </tb-th>
            <tb-td>&euro;{{ lead_data.alimony_amount }}</tb-td>
          </tb-row>
          <tb-row v-if="lead_data.college_debt > 1">
            <tb-th>
              <nio-icon icon="ni-calender-date"></nio-icon> {{ $t('Study')+' '+$t('Started') }}
            </tb-th>
            <tb-td>{{ parseInt(lead_data.study_start_point) === 1 ? $t('Before') : $t('After') }} 1-9-2015</tb-td>
          </tb-row>
          <tb-row>
            <tb-th>
              <nio-icon icon="ni-question"></nio-icon> {{ $t('Situation') }}
            </tb-th>
            <tb-td>{{ lead_data.current_situation }}</tb-td>
          </tb-row>
          <tb-row v-if="null !== lead_data.is_spotted">
            <tb-th>
              <nio-icon icon="ni-question"></nio-icon> {{ $t('House spotted') +'?' }}
            </tb-th>
            <tb-td>{{ parseInt(lead_data.is_spotted) === 0 ? $t('No') : $t('Yes') }}</tb-td>
          </tb-row>
          <tb-row v-if="lead_data.when_to_purchase">
            <tb-th>
              <nio-icon icon="ni-clock"></nio-icon> {{ $t('When To Purchase') }}
            </tb-th>
            <tb-td>{{ lead_data.when_to_purchase }}</tb-td>
          </tb-row>
        </template>
      </template>
      <tb-row v-if="!(lead_data instanceof Array) || lead_data.length > 6">
        <tb-td :col-span="2" class="text-center">
          <nk-button type="light" size="sm" v-on:click="displayExtras= !displayExtras">{{ displayExtras ? $t('Show')+' '+$t('Less') : $t('Show')+' '+$t('More') }}</nk-button>
        </tb-td>
      </tb-row>
    </list-table>
  </card>
</template>

<script>
import { IonSkeletonText } from "@ionic/vue"
import { Card } from '@core/components/cards'
import ListTable from "@core/components/list-table/ListTable";
import TbRow from "@core/components/data-tables/components/TbRow";
import TbTh from "@core/components/data-tables/components/TbTh";
import TbTd from "@core/components/data-tables/components/TbTd";
import {ref} from "vue";
import NkButton from "@core/components/button/NkButton";
export default {
  name: "LeadData",
  components: {NkButton, TbTd, TbTh, TbRow, ListTable, Card, IonSkeletonText},
  props: {
    lead_data: {
      required: true,
      type: Object,
    }
  },
  setup(){
    let displayExtras = ref(false)
    return{
      displayExtras,
    }
  },
}
</script>

<style scoped>
.ld_icon{
  font-size: 1.2rem;
}
</style>
