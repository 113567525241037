<template>
  <card>
    <h6 class="mt-0">{{ $t('Follow ups') }}</h6>
    <div style="transition: max-height 1s ease-in" :style="[timelineCollapsed && followupLogs.length > 1 ? 'max-height: 120px' : 'max-height: 2000px']" class="overflow-hidden">
      <div class="my-2" v-for="(fup,i) in followupLogs" :key="'ti_fup'+i">
        <div class="bg-primary-dim p-2 rounded">
          <div class="d-flex justify-content-between">
            <b>{{ $t('Attempt') }}#{{ (i+1) }}</b>
            <div class="small">
              <div class="lh-n nk-text-muted" v-if="fup.user">
                {{ $t('by')+' '+ fup.user.name}} ({{ $t(fup.user.role) }})
              </div>
              <span class="text-muted">{{$t('On')}}: </span>
              <span class="nk-text-dark mr-1">{{fup.followup_date}}</span>
              <span class="text-muted">{{$t('at')}}: </span>
              <span class="nk-text-dark">{{ fup.followup_time }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center fw-500 my-2">
            <nio-icon :icon="fup.info.outline ? 'ni-bullet-fill':'ni-bullet'" class="mr-1" :style="{color: fup.info?.color}"></nio-icon> {{$t(fup.info?.title || '')}}
          </div>
          <p class="text-muted lh-n" v-if="fup.info?.translation_id">{{ $t( 'followup_logs.'+fup.info?.translation_id || '', fup.info?.translation_params || {}) }}</p>
          <p class="text-muted lh-n" v-else>{{ $t(fup.info?.description || '') }}</p>
        </div>
        <div class="text-right small">
          <span class="nk-text-muted">{{$t('Logged on')}}:</span> <span class="nk-text-dark">{{fup.created_at}}</span>
        </div>
      </div>
    </div>
    <div v-if="followupLogs.length > 1" class="text-center pt-2" style="box-shadow: 0 -4px 3px 0 #0000000a;">
      <nk-button type="light" is-icon-only-button round v-on:click="timelineCollapsed= !timelineCollapsed">
        <nio-icon :icon="timelineCollapsed ? 'ni-chevron-down' : 'ni-chevron-up'"></nio-icon>
      </nk-button>
    </div>
  </card>
</template>

<script>
import Card from "@core/components/cards/Card";
import {computed, ref} from "vue";
export default {
  components: {Card},
  props:{
    followupLogs: Array,
  },
  setup(props){

    let timelineCollapsed = ref(true)

    const followupLogsComputed = computed(()=>{
      let data = []
      let prevItem = false
      for(let x in props.followupLogs){
        let item = Object.assign({}, props.followupLogs[x])
        if(!prevItem || prevItem.followup_date.substr(3) !== item.followup_date.substr(3)){
          item.timeline_head = item.followup_date.substr(3)
          prevItem = props.followupLogs[x]
        }
        item.followup_date = item.followup_date.substr(0, 5)
        data.push(item)
      }
      return data
    })
    return{
      followupLogsComputed,
      timelineCollapsed,
    }
  }
}
</script>
